import React, { useEffect, useState } from "react";
import GoogleMapReact from "google-map-react";
import AmbulanceList from "./AmbulanceList";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "bootstrap/dist/css/bootstrap.min.css";
import fb from "./Firebase";
import {
  getDatabase,
  ref,
  onValue,
  query,
  orderByKey,
  equalTo,
} from "firebase/database";

const AmublanceOnMap = () => (
  <div
    style={{
      position: "relative",
      display: "inline-block",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      backgroundColor: "rgba(255, 0, 0, 0.5)", // Red color with 50% opacity
    }}
  >
    <p
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        fontSize: "20px",
      }}
    >
      🚑
    </p>
  </div>
);

export default function SimpleMap() {
  const defaultProps = {
    center: {
      lat: 43.041069,
      lng: -87.909416,
    },
    zoom: 15,
  };

  const [coords, setCoords] = useState({
    lat: 43.041069,
    lng: -87.909416,
  });

  const [selectedHospitalUid, setSelectedHospitalUid] = useState(new URLSearchParams(window.location.search).get("hospitalUid"));

  const [incidents, setIncidents] = useState([]);

  useEffect(() => {

    const database = getDatabase();
    const incidentsRef = ref(database, "incidents");

    // Subscribe to changes in the incident collection
    const incidentsQuery = query(incidentsRef, orderByKey());
    onValue(incidentsQuery, (snapshot) => {
      const incidentsData = [];
      snapshot.forEach((childSnapshot) => {
        const incident = childSnapshot.val();
        // Filter incidents based on selected hospital
       // alert(selectedHospitalUid)
        if (incident.selectedHospital.uid === selectedHospitalUid) {
          incidentsData.push(incident);
        }
      });
      setIncidents(incidentsData);
    },[]);

    const interval = setInterval(() => {
      // Update coordinates to simulate movement
      setCoords((prevCoords) => ({
        lat: prevCoords.lat + 0.0001, // Adjust the increment as needed
        lng: prevCoords.lng + 0.0001, // Adjust the increment as needed
      }));
    }, 1000); // Update every 1 second (adjust as needed)

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <Container fluid>
      <Row>
        <Col sm={9}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyB6ZZBU0NcZi5FZtJiEfDKRuGXjrfvU-RQ",
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            {incidents.map((ambulance) => {
              if (ambulance.latitude && ambulance.longitude) {
                console.log(JSON.stringify(ambulance))
                return (
                  <AmublanceOnMap
                    lat={ambulance.latitude}
                    lng={ambulance.longitude}
                  />
                );
              }
            })}
          </GoogleMapReact>
        </Col>
        <Col sm={3}>
          <AmbulanceList incidents={incidents} />
        </Col>
      </Row>
    </Container>
  );
}
