import { initializeApp } from 'firebase/app';


const firebaseConfig = {
  apiKey: "AIzaSyCabMNhkk4SFmPAkkDe_u0dvvkUjyFS5SU",
  authDomain: "ambulance-app-e054b.firebaseapp.com",
  databaseURL: "https://ambulance-app-e054b-default-rtdb.firebaseio.com",
  projectId: "ambulance-app-e054b",
  storageBucket: "ambulance-app-e054b.appspot.com",
  messagingSenderId: "735354658867",
  appId: "1:735354658867:web:e4ecd4e71c65a50fe88346",
  measurementId: "G-RVCVL01JH8"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app

