import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import Chat from "./Chat";

const AmbulanceList = ({ incidents }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100vh",
        overflowY: "scroll",
        padding: "20px",
        zIndex: 100,
      }}
    >
      <h2>Inbound Ambulances</h2>
      {incidents.map((car, index) => (
        <Accordion key={index} defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Ambulance #{index + 1}</Accordion.Header>
            <Accordion.Body>
            {car.photo && (
                <div style={{ marginTop: '20px', }}>
                  <img src={car.photo} alt="Vehicle" style={{ maxWidth: '100%', maxHeight: '200px', margin:'auto', display:"block"  }} />
                </div>
              )}
              <hr />
              <ul>
                {Object.entries(car).map(([key, value]) => {
                  if (key !== 'selectedHospital' && key !== 'photo' && key !=='messages' && key !=='uid') {
                    return (
                      <li key={key}>
                        <strong>{key}: </strong>{value}
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
              <hr />
              {
                car.messages &&(
                  <div>
                    <h5>Chat</h5>
                    <Chat currentIncidentId={car.uid} />
                  </div>
                )
              }
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      ))}
    </div>
  );
};

export default AmbulanceList;
