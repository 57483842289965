import React, { useCallback, useEffect, useState } from 'react';
import {
    getDatabase,
    ref,
    onValue,
    query,
    orderByKey,
    equalTo,
    get,
    push
  } from "firebase/database";
import './Chat.css'
const Chat = ({ currentIncidentId }) => {
  const [inputText, setInputText] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Initialize Firebase database reference
    const incidentMessagesRef = ref(
      getDatabase(),
      `incidents/${currentIncidentId}/messages`
    );

    // Load initial messages from Firebase
    const loadMessages = async () => {
      try {
        const messagesSnapshot = await get(query(incidentMessagesRef, orderByKey()));
        const messagesData = [];
        messagesSnapshot.forEach((childSnapshot) => {
          messagesData.push(childSnapshot.val());
        });
        setMessages(messagesData); // Reverse the order to display the latest messages at the bottom
      } catch (error) {
        console.error("Error loading messages: ", error);
      }
    };

    loadMessages();


    // Subscribe to new messages in Firebase
   onValue(incidentMessagesRef, (snapshot) => {
      const newMessage = snapshot.val();
      const messagesData = [];
      snapshot.forEach((childSnapshot) => {
        messagesData.push(childSnapshot.val());
      });
      setMessages(messagesData); 
     /* if (newMessage) {
        setMessages((previousMessages) => GiftedChat.append([], messagesData));
      }*/
    });


  }, []);

  const handleUpdateMessagesInFirebase = useCallback(async (newMessage) => {
    try {
      const incidentMessagesRef = ref(
        getDatabase(),
        `incidents/${currentIncidentId}/messages`
      );
      const formattedMessage = {
        _id: crypto.randomUUID(),
        text: newMessage,
        user:{
            _id: 500,
            name:"HUC Dispatcher",
            avatar: 'https://t4.ftcdn.net/jpg/05/49/98/39/360_F_549983970_bRCkYfk0P6PP5fKbMhZMIb07mCJ6esXL.jpg',
          }
      }
      await push(incidentMessagesRef, formattedMessage);
      setInputText('')
    } catch (error) {
      console.error("Error updating messages: ", error);
    }
  }, [currentIncidentId]);

  const onSend = useCallback((newMessage = []) => {
    // Update state to show new messages
    /*setMessages((previousMessages) =>
    messages.join(previousMessages, newMessage)
  );*/
    // Call function to update messages in Firebase
    handleUpdateMessagesInFirebase(newMessage);
  }, [handleUpdateMessagesInFirebase]);

  return (
    <div className="chat-container">
    <div className="chat-messages">
      {Object.values(messages).map((message) =>{
        if(message.user._id === 1){
          // Align left gray bubble
          return(
            <div key={message._id} className="message left">
              <img style={{height:20,width:20}} src={message.user.avatar} alt={message.user.name} className="avatar" />
              <span className="message-text">{message.text}</span>
            </div>
          )
        } else {
          // Align right blue bubble
          return(
            <div key={message._id} className="message right">
              <span className="message-text">{message.text}</span>
            </div>
          )
        }
      })}
    </div>
    <div className="chat-input">
      <input
        type="text"
        placeholder="Type your message..."
        value={inputText}
        onChange={(e) => setInputText(e.target.value)}
      />
      <button onClick={()=>onSend(inputText)}>Send</button>
    </div>
  </div>
  );
};



export default Chat;